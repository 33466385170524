import { ChainId, Token } from 'parsec-swap-sdk'

export const COLA: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xdFe5e9286297e47f7026BD193573dbCF303E7019',
    18,
    'COLA',
    'ZilionixxSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xdFe5e9286297e47f7026BD193573dbCF303E7019',
    18,
    'COLA',
    'ZilionixxSwap Token',
  ),
}

export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xb2262C29DAA309F89cFc4Ce1Ef2a3CA3E6fc1f6f',
    18,
    'BUSD',
    'InveCoin USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0x66C34276bD359a4D5Dd9Aa18762cB22Aca21df28',
    18,
    'BUSD',
    'InveCoin USD',
  ),
}

export const WBNB = new Token(ChainId.TESTNET, '0xac201451AfBB56120dfeF2eAA2998E2D183D2041', 18, 'WBNB', 'Wrapped PSC')
export const DAI = new Token(ChainId.MAINNET, '0x1c76192D5a6ED099F0E55f82F435b409F0D25E09', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0xc7A3580B15b79584833E1d75236f52C8675EE224', 6, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
  UNE: {
    symbol: 'UNE',
    projectLink: 'https://www.binance.com/',
    address: {
      56: '',
      1120: '0xac201451AfBB56120dfeF2eAA2998E2D183D2041',
    },
    decimals: 18,
  },
  busd: { 
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      1120: '0x66C34276bD359a4D5Dd9Aa18762cB22Aca21df28',
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  syrup: {
    symbol: 'SYRUP',
    address: {
      56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
      1120: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
 
  Parsec: {
    symbol: 'Parsec',
    address: {
      56: '0x45870C2b385EAC7ffb1342600fAc2Ad70C62fd80',
      1120: '0xd878Ad224225C86047ff096c4977833b73C937a5',
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059ff775485246999027b3197955',
      1120: '0xc7A3580B15b79584833E1d75236f52C8675EE224',
    },
    decimals: 6,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cola: {
    symbol: 'COLA',
    address: {
      56: '0x500d72BD1F11E4D66E9AE7566976ff1dE3691dA1',
      1120: '0xBF921f94Fd9eF1738bE25D8CeCFDFE2C822c81B0',
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wbnb: {
    symbol: 'WBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      1120: '0xac201451AfBB56120dfeF2eAA2998E2D183D2041',
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  busdInvLP: {
    symbol: 'BUSD-MOS LP',
    address: { 
      56: '',
      1120: '0x2D54D6E69D69762BCEB41e90c87ebDc2e611B6f6', 
    },
    decimals: 18,
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gmi: {
    address: { 56: '0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846', 1120: '' },
    decimals: 18,
    symbol: 'GMI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tlos: {
    address: { 56: '0xb6C53431608E626AC81a9776ac3e999c5556717c', 1120: '' },
    decimals: 18,
    symbol: 'TLOS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  beta: {
    address: { 56: '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28', 1120: '' },
    decimals: 18,
    symbol: 'BETA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nft: {
    address: { 56: '0x1fC9004eC7E5722891f5f38baE7678efCB11d34D', 1120: '' },
    decimals: 6,
    symbol: 'NFT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  stephero: {
    address: { 56: '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545', 1120: '' },
    decimals: 18,
    symbol: 'HERO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pros: {
    address: { 56: '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405', 1120: '' },
    decimals: 18,
    symbol: 'PROS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  qbt: {
    address: { 56: '0x17B7163cf1Dbd286E262ddc68b553D899B93f526', 1120: '' },
    decimals: 18,
    symbol: 'QBT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cvp: {
    address: { 56: '0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E', 1120: '' },
    decimals: 18,
    symbol: 'CVP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bscdefi: {
    address: { 56: '0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3', 1120: '' },
    decimals: 18,
    symbol: 'BSCDEFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dai: {
    address: { 56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
     1120: '0x1c76192D5a6ED099F0E55f82F435b409F0D25E09' },
    decimals: 18,
    symbol: 'DAI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  btcb: {
    address: { 56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 1120: '' },
    decimals: 18,
    symbol: 'BTCB',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ust: {
    address: { 56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC', 1120: '' },
    decimals: 18,
    symbol: 'UST',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  eth: {
    address: { 56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 1120: '' },
    decimals: 18,
    symbol: 'ETH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  usdc: {
    address: { 56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 1120: '' },
    decimals: 18,
    symbol: 'USDC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  kalm: {
    address: { 56: '0x4BA0057f784858a48fe351445C672FF2a3d43515', 1120: '' },
    decimals: 18,
    symbol: 'KALM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mos: {
    address: { 56:'',
    1120:'0x9a7E03F1A79c7dE58897d3F223b6c0E7f3f3c01C' },
    decimals: 18,
    symbol: 'MOS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hotcross: {
    address: { 56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6', 1120: '' },
    decimals: 18,
    symbol: 'HOTCROSS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  belt: {
    address: { 56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f', 1120: '' },
    decimals: 18,
    symbol: 'BELT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  watch: {
    address: { 56: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0', 1120: '' },
    decimals: 18,
    symbol: 'WATCH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bry: {
    address: { 56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830', 1120: '' },
    decimals: 18,
    symbol: 'BRY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wsote: {
    address: { 56: '0x541E619858737031A1244A5d0Cd47E5ef480342c', 1120: '' },
    decimals: 18,
    symbol: 'wSOTE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  helmet: {
    address: { 56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8', 1120: '' },
    decimals: 18,
    symbol: 'Helmet',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ten: {
    address: { 56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C', 1120: '' },
    decimals: 18,
    symbol: 'TEN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ditto: {
    address: { 56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715', 1120: '' },
    decimals: 9,
    symbol: 'DITTO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  blink: {
    address: { 56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F', 1120: '' },
    decimals: 6,
    symbol: 'BLINK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pha: {
    address: { 56: '0x0112e557d400474717056C4e6D40eDD846F38351', 1120: '' },
    decimals: 18,
    symbol: 'PHA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  babycake: {
    address: { 56: '0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c', 1120: '' },
    decimals: 18,
    symbol: 'BABYCAKE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bmon: {
    address: { 56: '0x08ba0619b1e7A582E0BCe5BBE9843322C954C340', 1120: '' },
    decimals: 18,
    symbol: 'BMON',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hero: {
    address: { 56: '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13', 1120: '' },
    decimals: 18,
    symbol: 'HERO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wsg: {
    address: { 56: '0xA58950F05FeA2277d2608748412bf9F802eA4901', 1120: '' },
    decimals: 18,
    symbol: 'WSG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mcrn: {
    address: { 56: '0xacb2d47827C9813AE26De80965845D80935afd0B', 1120: '' },
    decimals: 18,
    symbol: 'MCRN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  revv: {
    address: { 56: '0x833F307aC507D47309fD8CDD1F835BeF8D702a93', 1120: '' },
    decimals: 18,
    symbol: 'REVV',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  skill: {
    address: { 56: '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab', 1120: '' },
    decimals: 18,
    symbol: 'SKILL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  if: {
    address: { 56: '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1', 1120: '' },
    decimals: 18,
    symbol: 'IF',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sps: {
    address: { 56: '0x1633b7157e7638C4d6593436111Bf125Ee74703F', 1120: '' },
    decimals: 18,
    symbol: 'SPS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  chess: {
    address: { 56: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6', 1120: '' },
    decimals: 18,
    symbol: 'CHESS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  titan: {
    address: { 56: '0xe898EDc43920F357A93083F1d4460437dE6dAeC2', 1120: '' },
    decimals: 18,
    symbol: 'TITAN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  harmony: {
    address: { 56: '0x03fF0ff224f904be3118461335064bB48Df47938', 1120: '' },
    decimals: 18,
    symbol: 'ONE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mask: {
    address: { 56: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3', 1120: '' },
    decimals: 18,
    symbol: 'MASK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dvi: {
    address: { 56: '0x758FB037A375F17c7e195CC634D77dA4F554255B', 1120: '' },
    decimals: 18,
    symbol: 'DVI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  adx: {
    address: { 56: '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819', 1120: '' },
    decimals: 18,
    symbol: 'ADX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bscpad: {
    address: { 56: '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700', 1120: '' },
    decimals: 18,
    symbol: 'BSCPAD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  rabbit: {
    address: { 56: '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41', 1120: '' },
    decimals: 18,
    symbol: 'RABBIT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  form: {
    address: { 56: '0x25A528af62e56512A19ce8c3cAB427807c28CC19', 1120: '' },
    decimals: 18,
    symbol: 'FORM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  txl: {
    address: { 56: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5', 1120: '' },
    decimals: 18,
    symbol: 'TXL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  orbs: {
    address: { 56: '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80', 1120: '' },
    decimals: 18,
    symbol: 'ORBS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cos: {
    address: { 56: '0x96Dd399F9c3AFda1F194182F71600F1B65946501', 1120: '' },
    decimals: 18,
    symbol: 'COS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bunny: {
    address: { 56: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51', 1120: '' },
    decimals: 18,
    symbol: 'BUNNY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  alice: {
    address: { 56: '0xAC51066d7bEC65Dc4589368da368b212745d63E8', 1120: '' },
    decimals: 6,
    symbol: 'ALICE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  for: {
    address: { 56: '0x658A109C5900BC6d2357c87549B651670E5b0539', 1120: '' },
    decimals: 18,
    symbol: 'FOR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bux: {
    address: { 56: '0x211FfbE424b90e25a15531ca322adF1559779E45', 1120: '' },
    decimals: 18,
    symbol: 'BUX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nuls: {
    address: { 56: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B', 1120: '' },
    decimals: 8,
    symbol: 'NULS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ramp: {
    address: { 56: '0x8519EA49c997f50cefFa444d240fB655e89248Aa', 1120: '' },
    decimals: 18,
    symbol: 'RAMP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bfi: {
    address: { 56: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b', 1120: '' },
    decimals: 18,
    symbol: 'BFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dexe: {
    address: { 56: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6', 1120: '' },
    decimals: 18,
    symbol: 'DEXE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bel: {
    address: { 56: '0x8443f091997f06a61670B735ED92734F5628692F', 1120: '' },
    decimals: 18,
    symbol: 'BEL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tpt: {
    address: { 56: '0xECa41281c24451168a37211F0bc2b8645AF45092', 1120: '' },
    decimals: 4,
    symbol: 'TPT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xmark: {
    address: { 56: '0x26A5dFab467d4f58fB266648CAe769503CEC9580', 1120: '' },
    decimals: 9,
    symbol: 'xMARK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bmxx: {
    address: { 56: '0x4131b87F74415190425ccD873048C708F8005823', 1120: '' },
    decimals: 18,
    symbol: 'bMXX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  iotx: {
    address: { 56: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5', 1120: '' },
    decimals: 18,
    symbol: 'IOTX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bor: {
    address: { 56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241', 1120: '' },
    decimals: 18,
    symbol: 'BOR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bopen: {
    address: { 56: '0xF35262a9d427F96d2437379eF090db986eaE5d42', 1120: '' },
    decimals: 18,
    symbol: 'bOPEN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dodo: {
    address: { 56: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2', 1120: '' },
    decimals: 18,
    symbol: 'DODO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  swingby: {
    address: { 56: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739', 1120: '' },
    decimals: 18,
    symbol: 'SWINGBY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  zee: {
    address: { 56: '0x44754455564474A89358B2C2265883DF993b12F0', 1120: '' },
    decimals: 18,
    symbol: 'ZEE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  swgb: {
    address: { 56: '0xE40255C5d7fa7ceEc5120408C78C787CECB4cfdb', 1120: '' },
    decimals: 18,
    symbol: 'SWGb',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  swg: {
    address: { 56: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B', 1120: '' },
    decimals: 18,
    symbol: 'SWG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sfp: {
    address: { 56: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb', 1120: '' },
    decimals: 18,
    symbol: 'SFP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lina: {
    address: { 56: '0x762539b45A1dCcE3D36d080F74d1AED37844b878', 1120: '' },
    decimals: 18,
    symbol: 'LINA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lit: {
    address: { 56: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723', 1120: '' },
    decimals: 18,
    symbol: 'LIT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hget: {
    address: { 56: '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731', 1120: '' },
    decimals: 6,
    symbol: 'HGET',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bdo: {
    address: { 56: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454', 1120: '' },
    decimals: 18,
    symbol: 'BDO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  egld: {
    address: { 56: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe', 1120: '' },
    decimals: 18,
    symbol: 'EGLD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  front: {
    address: { 56: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b', 1120: '' },
    decimals: 18,
    symbol: 'FRONT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  btc: {
    address: { 56: '0x78650B139471520656b9E7aA7A5e9276814a38e9', 1120: '0x1D395f9d0e438Ca1bBA4d57BD590CE820A9f7206' },
    decimals: 17,
    symbol: 'uBTC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bscx: {
    address: { 56: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587', 1120: '' },
    decimals: 18,
    symbol: 'BSCX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  balbt: {
    address: { 56: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7', 1120: '' },
    decimals: 18,
    symbol: 'bALBT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  asr: {
    address: { 56: '0x80D5f92C2c8C682070C95495313dDB680B267320', 1120: '' },
    decimals: 2,
    symbol: 'ASR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  atm: {
    address: { 56: '0x25E9d05365c867E59C1904E7463Af9F312296f9E', 1120: '' },
    decimals: 2,
    symbol: 'ATM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  og: {
    address: { 56: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c', 1120: '' },
    decimals: 2,
    symbol: 'OG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  reef: {
    address: { 56: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e', 1120: '' },
    decimals: 18,
    symbol: 'REEF',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  juv: {
    address: { 56: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B', 1120: '' },
    decimals: 2,
    symbol: 'JUV',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  psg: {
    address: { 56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1', 1120: '' },
    decimals: 2,
    symbol: 'PSG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  vai: {
    address: { 56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7', 1120: '' },
    decimals: 18,
    symbol: 'VAI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  unfi: {
    address: { 56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B', 1120: '' },
    decimals: 18,
    symbol: 'UNFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  twt: {
    address: { 56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003', 1120: '' },
    decimals: 18,
    symbol: 'TWT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hard: {
    address: { 56: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4', 1120: '' },
    decimals: 6,
    symbol: 'HARD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  broobee: {
    address: { 56: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe', 1120: '' },
    decimals: 18,
    symbol: 'bROOBEE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  stax: {
    address: { 56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4', 1120: '' },
    decimals: 18,
    symbol: 'STAX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nar: {
    address: { 56: '0xA1303E6199b319a891b79685F0537D289af1FC83', 1120: '' },
    decimals: 18,
    symbol: 'NAR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nya: {
    address: { 56: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5', 1120: '' },
    decimals: 18,
    symbol: 'NYA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ctk: {
    address: { 56: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929', 1120: '' },
    decimals: 6,
    symbol: 'CTK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  inj: {
    address: { 56: '0xa2B726B1145A4773F68593CF171187d8EBe4d495', 1120: '' },
    decimals: 18,
    symbol: 'INJ',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sxp: {
    address: { 56: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A', 1120: '' },
    decimals: 18,
    symbol: 'SXP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  alpha: {
    address: { 56: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975', 1120: '' },
    decimals: 18,
    symbol: 'ALPHA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xvs: {
    address: { 56: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63', 1120: '' },
    decimals: 18,
    symbol: 'XVS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sushi: {
    address: { 56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4', 1120: '' },
    decimals: 18,
    symbol: 'SUSHI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  comp: {
    address: { 56: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8', 1120: '' },
    decimals: 18,
    symbol: 'COMP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bifi: {
    address: { 56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A', 1120: '' },
    decimals: 18,
    symbol: 'BIFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dusk: {
    address: { 56: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C', 1120: '' },
    decimals: 18,
    symbol: 'DUSK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ueth: {
    address: { 56: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B', 1120: '0xDC04766f51C946E23d2F4c16A8A7ab1C61F22DF4' },
    decimals: 18,
    symbol: 'BETH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mamzn: {
    address: { 56: '0x3947B992DC0147D2D89dF0392213781b04B25075', 1120: '' },
    decimals: 18,
    symbol: 'mAMZN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mgoogl: {
    address: { 56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f', 1120: '' },
    decimals: 18,
    symbol: 'mGOOGL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mnflx: {
    address: { 56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc', 1120: '' },
    decimals: 18,
    symbol: 'mNFLX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mtsla: {
    address: { 56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3', 1120: '' },
    decimals: 18,
    symbol: 'mTSLA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ltc: {
    address: { 56: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94', 1120: '' },
    decimals: 18,
    symbol: 'LTC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ada: {
    address: { 56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47', 1120: '' },
    decimals: 18,
    symbol: 'ADA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  band: {
    address: { 56: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18', 1120: '' },
    decimals: 18,
    symbol: 'BAND',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dot: {
    address: { 56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402', 1120: '' },
    decimals: 18,
    symbol: 'DOT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  eos: {
    address: { 56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6', 1120: '' },
    decimals: 18,
    symbol: 'EOS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  link: {
    address: { 56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD', 1120: '' },
    decimals: 18,
    symbol: 'LINK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xrp: {
    address: { 56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE', 1120: '' },
    decimals: 18,
    symbol: 'XRP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  atom: {
    address: { 56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335', 1120: '' },
    decimals: 18,
    symbol: 'ATOM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  yfii: {
    address: { 56: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5', 1120: '' },
    decimals: 18,
    symbol: 'YFII',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xtz: {
    address: { 56: '0x16939ef78684453bfDFb47825F8a5F714f12623a', 1120: '' },
    decimals: 18,
    symbol: 'XTZ',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bch: {
    address: { 56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf', 1120: '' },
    decimals: 18,
    symbol: 'BCH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  yfi: {
    address: { 56: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e', 1120: '' },
    decimals: 18,
    symbol: 'YFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  uni: {
    address: { 56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1', 1120: '' },
    decimals: 18,
    symbol: 'UNI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  fil: {
    address: { 56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153', 1120: '' },
    decimals: 18,
    symbol: 'FIL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bake: {
    address: { 56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5', 1120: '' },
    decimals: 18,
    symbol: 'BAKE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  burger: {
    address: { 56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f', 1120: '' },
    decimals: 18,
    symbol: 'BURGER',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bdigg: {
    address: { 56: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA', 1120: '' },
    decimals: 18,
    symbol: 'bDIGG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bbadger: {
    address: { 56: '0x1F7216fdB338247512Ec99715587bb97BBf96eae', 1120: '' },
    decimals: 18,
    symbol: 'bBadger',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  trade: {
    address: { 56: '0x7af173F350D916358AF3e218Bdf2178494Beb748', 1120: '' },
    decimals: 18,
    symbol: 'TRADE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pnt: {
    address: { 56: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92', 1120: '' },
    decimals: 18,
    symbol: 'PNT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mir: {
    address: { 56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9', 1120: '' },
    decimals: 18,
    symbol: 'MIR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pbtc: {
    address: { 56: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C', 1120: '' },
    decimals: 18,
    symbol: 'pBTC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lto: {
    address: { 56: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd', 1120: '' },
    decimals: 18,
    symbol: 'LTO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pcws: {
    address: { 56: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd', 1120: '' },
    decimals: 18,
    symbol: 'pCWS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  zil: {
    address: { 56: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787', 1120: '' },
    decimals: 12,
    symbol: 'ZIL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lien: {
    address: { 56: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3', 1120: '' },
    decimals: 8,
    symbol: 'LIEN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  swth: {
    address: { 56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C', 1120: '' },
    decimals: 8,
    symbol: 'SWTH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dft: {
    address: { 56: '0x42712dF5009c20fee340B245b510c0395896cF6e', 1120: '' },
    decimals: 18,
    symbol: 'DFT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gum: {
    address: { 56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462', 1120: '' },
    decimals: 18,
    symbol: 'GUM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dego: {
    address: { 56: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC', 1120: '' },
    decimals: 18,
    symbol: 'DEGO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nrv: {
    address: { 56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096', 1120: '' },
    decimals: 18,
    symbol: 'NRV',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  easy: {
    address: { 56: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B', 1120: '' },
    decimals: 18,
    symbol: 'EASY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  oddz: {
    address: { 56: '0xCD40F2670CF58720b694968698A5514e924F742d', 1120: '' },
    decimals: 18,
    symbol: 'ODDZ',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hoo: {
    address: { 56: '0xE1d1F66215998786110Ba0102ef558b22224C016', 1120: '' },
    decimals: 8,
    symbol: 'HOO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  apys: {
    address: { 56: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7', 1120: '' },
    decimals: 18,
    symbol: 'APYS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bondly: {
    address: { 56: '0x96058f8C3e16576D9BD68766f3836d9A33158f89', 1120: '' },
    decimals: 18,
    symbol: 'BONDLY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tko: {
    address: { 56: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809', 1120: '' },
    decimals: 18,
    symbol: 'TKO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  itam: {
    address: { 56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B', 1120: '' },
    decimals: 18,
    symbol: 'ITAM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  arpa: {
    address: { 56: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e', 1120: '' },
    decimals: 18,
    symbol: 'ARPA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  eps: {
    address: { 56: '0xA7f552078dcC247C2684336020c03648500C6d9F', 1120: '' },
    decimals: 18,
    symbol: 'EPS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  jgn: {
    address: { 56: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75', 1120: '' },
    decimals: 18,
    symbol: 'JGN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tlm: {
    address: { 56: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95', 1120: '' },
    decimals: 4,
    symbol: 'TLM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  perl: {
    address: { 56: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B', 1120: '' },
    decimals: 18,
    symbol: 'PERL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  alpa: {
    address: { 56: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03', 1120: '' },
    decimals: 18,
    symbol: 'ALPA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hzn: {
    address: { 56: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD', 1120: '' },
    decimals: 18,
    symbol: 'HZN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  suter: {
    address: { 56: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055', 1120: '' },
    decimals: 18,
    symbol: 'SUTER',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cgg: {
    address: { 56: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2', 1120: '' },
    decimals: 18,
    symbol: 'CGG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mix: {
    address: { 56: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197', 1120: '' },
    decimals: 18,
    symbol: 'MIX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hakka: {
    address: { 56: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC', 1120: '' },
    decimals: 18,
    symbol: 'HAKKA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xed: {
    address: { 56: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f', 1120: '' },
    decimals: 18,
    symbol: 'XED',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  τbtc: {
    address: { 56: '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c', 1120: '' },
    decimals: 9,
    symbol: 'τBTC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  alpaca: {
    address: { 56: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F', 1120: '' },
    decimals: 18,
    symbol: 'ALPACA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dfd: {
    address: { 56: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff', 1120: '' },
    decimals: 18,
    symbol: 'DFD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lmt: {
    address: { 56: '0x9617857E191354dbEA0b714d78Bc59e57C411087', 1120: '' },
    decimals: 18,
    symbol: 'LMT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bttold: {
    address: { 56: '0x8595F9dA7b868b1822194fAEd312235E43007b49', 1120: '' },
    decimals: 18,
    symbol: 'BTTOLD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  trx: {
    address: { 56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B', 1120: '' },
    decimals: 18,
    symbol: 'TRX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  win: {
    address: { 56: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99', 1120: '' },
    decimals: 18,
    symbol: 'WIN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mcoin: {
    address: { 56: '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f', 1120: '' },
    decimals: 18,
    symbol: 'MCOIN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  math: {
    address: { 56: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983', 1120: '' },
    decimals: 18,
    symbol: 'MATH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  kun: {
    address: { 56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584', 1120: '' },
    decimals: 18,
    symbol: 'KUN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  qsd: {
    address: { 56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2', 1120: '' },
    decimals: 18,
    symbol: 'QSD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hyfi: {
    address: { 56: '0x9a319b959e33369C5eaA494a770117eE3e585318', 1120: '' },
    decimals: 18,
    symbol: 'HYFI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  oin: {
    address: { 56: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA', 1120: '' },
    decimals: 8,
    symbol: 'OIN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  doge: {
    address: { 56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43', 1120: '' },
    decimals: 8,
    symbol: 'DOGE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  fine: {
    address: { 56: '0x4e6415a5727ea08aAE4580057187923aeC331227', 1120: '' },
    decimals: 18,
    symbol: 'FINE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  one: {
    address: { 56: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0', 1120: '' },
    decimals: 18,
    symbol: 'ONE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pmon: {
    address: { 56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2', 1120: '' },
    decimals: 18,
    symbol: 'PMON',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  τdoge: {
    address: { 56: '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A', 1120: '' },
    decimals: 8,
    symbol: 'τDOGE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  btr: {
    address: { 56: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3', 1120: '' },
    decimals: 18,
    symbol: 'BTR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ubxt: {
    address: { 56: '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811', 1120: '' },
    decimals: 18,
    symbol: 'UBXT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wmass: {
    address: { 56: '0x7e396BfC8a2f84748701167c2d622F041A1D7a17', 1120: '' },
    decimals: 8,
    symbol: 'WMASS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  rfox: {
    address: { 56: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5', 1120: '' },
    decimals: 18,
    symbol: 'RFOX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xend: {
    address: { 56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608', 1120: '' },
    decimals: 18,
    symbol: 'XEND',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cyc: {
    address: { 56: '0x810EE35443639348aDbbC467b33310d2AB43c168', 1120: '' },
    decimals: 18,
    symbol: 'CYC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  chr: {
    address: { 56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE', 1120: '' },
    decimals: 6,
    symbol: 'CHR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  deri: {
    address: { 56: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5', 1120: '' },
    decimals: 18,
    symbol: 'DERI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  well: {
    address: { 56: '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5', 1120: '' },
    decimals: 18,
    symbol: 'WELL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wex: {
    address: { 56: '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90', 1120: '' },
    decimals: 18,
    symbol: 'WEX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  waultx: {
    address: { 56: '0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21', 1120: '' },
    decimals: 18,
    symbol: 'WAULTx',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  popen: {
    address: { 56: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5', 1120: '' },
    decimals: 18,
    symbol: 'pOPEN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ez: {
    address: { 56: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83', 1120: '' },
    decimals: 18,
    symbol: 'EZ',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  vrt: {
    address: { 56: '0x5F84ce30DC3cF7909101C69086c50De191895883', 1120: '' },
    decimals: 18,
    symbol: 'VRT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tusd: {
    address: { 56: '0x14016E85a25aeb13065688cAFB43044C2ef86784', 1120: '' },
    decimals: 18,
    symbol: 'TUSD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mtrg: {
    address: { 56: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F', 1120: '' },
    decimals: 18,
    symbol: 'MTRG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ktn: {
    address: { 56: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E', 1120: '' },
    decimals: 18,
    symbol: 'KTN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  qkc: {
    address: { 56: '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da', 1120: '' },
    decimals: 18,
    symbol: 'QKC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bcfx: {
    address: { 56: '0x045c4324039dA91c52C55DF5D785385Aab073DcF', 1120: '' },
    decimals: 18,
    symbol: 'bCFX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mx: {
    address: { 56: '0x9F882567A62a5560d147d64871776EeA72Df41D3', 1120: '' },
    decimals: 18,
    symbol: 'MX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ata: {
    address: { 56: '0xA2120b9e674d3fC3875f415A7DF52e382F141225', 1120: '' },
    decimals: 18,
    symbol: 'ATA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mbox: {
    address: { 56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377', 1120: '' },
    decimals: 18,
    symbol: 'MBOX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  boring: {
    address: { 56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F', 1120: '' },
    decimals: 18,
    symbol: 'BORING',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  marsh: {
    address: { 56: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256', 1120: '' },
    decimals: 18,
    symbol: 'MARSH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ampl: {
    address: { 56: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F', 1120: '' },
    decimals: 9,
    symbol: 'AMPL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  o3: {
    address: { 56: '0xEe9801669C6138E84bD50dEB500827b776777d28', 1120: '' },
    decimals: 18,
    symbol: 'O3',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  hai: {
    address: { 56: '0xaA9E582e5751d703F85912903bacADdFed26484C', 1120: '' },
    decimals: 8,
    symbol: 'HAI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  htb: {
    address: { 56: '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea', 1120: '' },
    decimals: 18,
    symbol: 'HTB',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  woo: {
    address: { 56: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B', 1120: '' },
    decimals: 18,
    symbol: 'WOO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  $dg: {
    address: { 56: '0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19', 1120: '' },
    decimals: 18,
    symbol: '$DG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  safemoon: {
    address: { 56: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3', 1120: '' },
    decimals: 9,
    symbol: 'SAFEMOON',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  axs: {
    address: { 56: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0', 1120: '' },
    decimals: 18,
    symbol: 'AXS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  c98: {
    address: { 56: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6', 1120: '' },
    decimals: 18,
    symbol: 'c98',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pots: {
    address: { 56: '0x3Fcca8648651E5b974DD6d3e50F61567779772A8', 1120: '' },
    decimals: 18,
    symbol: 'POTS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gnt: {
    address: { 56: '0xF750A26EB0aCf95556e8529E72eD530f3b60f348', 1120: '' },
    decimals: 18,
    symbol: 'GNT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  rusd: {
    address: { 56: '0x07663837218A003e66310a01596af4bf4e44623D', 1120: '' },
    decimals: 18,
    symbol: 'rUSD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bp: {
    address: { 56: '0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1', 1120: '' },
    decimals: 18,
    symbol: 'BP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sfund: {
    address: { 56: '0x477bC8d23c634C154061869478bce96BE6045D12', 1120: '' },
    decimals: 18,
    symbol: 'SFUND',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  naos: {
    address: { 56: '0x758d08864fB6cCE3062667225ca10b8F00496cc2', 1120: '' },
    decimals: 18,
    symbol: 'NAOS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  cart: {
    address: { 56: '0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e', 1120: '' },
    decimals: 18,
    symbol: 'CART',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  light: {
    address: { 56: '0x037838b556d9c9d654148a284682C55bB5f56eF4', 1120: '' },
    decimals: 18,
    symbol: 'LIGHT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  rpg: {
    address: { 56: '0xc2098a8938119A52B1F7661893c0153A6CB116d5', 1120: '' },
    decimals: 18,
    symbol: 'RPG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  mcb: {
    address: { 56: '0x5fE80d2CD054645b9419657d3d10d26391780A7B', 1120: '' },
    decimals: 18,
    symbol: 'MCB',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  lazio: {
    address: { 56: '0x77d547256A2cD95F32F67aE0313E450Ac200648d', 1120: '' },
    decimals: 8,
    symbol: 'LAZIO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  arv: {
    address: { 56: '0x6679eB24F59dFe111864AEc72B443d1Da666B360', 1120: '' },
    decimals: 8,
    symbol: 'ARV',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  moni: {
    address: { 56: '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0', 1120: '' },
    decimals: 18,
    symbol: 'MONI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xms: {
    address: { 56: '0x7859B01BbF675d67Da8cD128a50D155cd881B576', 1120: '' },
    decimals: 18,
    symbol: 'XMS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  zoo: {
    address: { 56: '0x1D229B958D5DDFca92146585a8711aECbE56F095', 1120: '' },
    decimals: 18,
    symbol: 'ZOO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  fina: {
    address: { 56: '0x426c72701833fdDBdFc06c944737C6031645c708', 1120: '' },
    decimals: 18,
    symbol: 'FINA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dar: {
    address: { 56: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978', 1120: '' },
    decimals: 6,
    symbol: 'DAR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xwg: {
    address: { 56: '0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc', 1120: '' },
    decimals: 18,
    symbol: 'XWG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  eternal: {
    address: { 56: '0xD44FD09d74cd13838F137B590497595d6b3FEeA4', 1120: '' },
    decimals: 18,
    symbol: 'ETERNAL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  porto: {
    address: { 56: '0x49f2145d6366099e13B10FbF80646C0F377eE7f6', 1120: '' },
    decimals: 8,
    symbol: 'PORTO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  kart: {
    address: { 56: '0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10', 1120: '' },
    decimals: 18,
    symbol: 'KART',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  qi: {
    address: { 56: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5', 1120: '' },
    decimals: 18,
    symbol: 'QI',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sheesha: {
    address: { 56: '0x232FB065D9d24c34708eeDbF03724f2e95ABE768', 1120: '' },
    decimals: 18,
    symbol: 'SHEESHA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bcoin: {
    address: { 56: '0x00e1656e45f18ec6747F5a8496Fd39B50b38396D', 1120: '' },
    decimals: 18,
    symbol: 'BCOIN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  quidd: {
    address: { 56: '0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed', 1120: '' },
    decimals: 18,
    symbol: 'QUIDD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  santos: {
    address: { 56: '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7', 1120: '' },
    decimals: 8,
    symbol: 'SANTOS',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  nabox: {
    address: { 56: '0x755f34709E369D37C6Fa52808aE84A32007d1155', 1120: '' },
    decimals: 18,
    symbol: 'NABOX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xcv: {
    address: { 56: '0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8', 1120: '' },
    decimals: 18,
    symbol: 'XCV',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  idia: {
    address: { 56: '0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89', 1120: '' },
    decimals: 18,
    symbol: 'IDIA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tt: {
    address: { 56: '0x990E7154bB999FAa9b2fa5Ed29E822703311eA85', 1120: '' },
    decimals: 18,
    symbol: 'TT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gmee: {
    address: { 56: '0x84e9a6F9D240FdD33801f7135908BfA16866939A', 1120: '' },
    decimals: 18,
    symbol: 'GMEE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  htd: {
    address: { 56: '0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f', 1120: '' },
    decimals: 18,
    symbol: 'HTD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  dpt: {
    address: { 56: '0xE69cAef10A488D7AF31Da46c89154d025546e990', 1120: '' },
    decimals: 18,
    symbol: 'DPT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  thg: {
    address: { 56: '0x9fD87aEfe02441B123c3c32466cD9dB4c578618f', 1120: '' },
    decimals: 18,
    symbol: 'THG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ccar: {
    address: { 56: '0x50332bdca94673F33401776365b66CC4e81aC81d', 1120: '' },
    decimals: 18,
    symbol: 'CCAR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  high: {
    address: { 56: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63', 1120: '' },
    decimals: 18,
    symbol: 'HIGH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  sdao: {
    address: { 56: '0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240', 1120: '' },
    decimals: 18,
    symbol: 'SDAO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  antex: {
    address: { 56: '0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C', 1120: '' },
    decimals: 8,
    symbol: 'ANTEX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bbt: {
    address: { 56: '0xD48474E7444727bF500a32D5AbE01943f3A59A64', 1120: '' },
    decimals: 8,
    symbol: 'BBT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  woop: {
    address: { 56: '0x8b303d5BbfBbf46F1a4d9741E491e06986894e18', 1120: '' },
    decimals: 18,
    symbol: 'WOOP',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gm: {
    address: { 56: '0xe2604C9561D490624AA35e156e65e590eB749519', 1120: '' },
    decimals: 18,
    symbol: 'GM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  aog: {
    address: { 56: '0x40C8225329Bd3e28A043B029E0D07a5344d2C27C', 1120: '' },
    decimals: 18,
    symbol: 'AOG',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  '8pay': {
    address: { 56: '0xFeea0bDd3D07eb6FE305938878C0caDBFa169042', 1120: '' },
    decimals: 18,
    symbol: '8PAY',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bath: {
    address: { 56: '0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE', 1120: '' },
    decimals: 18,
    symbol: 'BATH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  insur: {
    address: { 56: '0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30', 1120: '' },
    decimals: 18,
    symbol: 'INSUR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  froyo: {
    address: { 56: '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9', 1120: '' },
    decimals: 18,
    symbol: 'FROYO',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  apx: {
    address: { 56: '0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3', 1120: '' },
    decimals: 18,
    symbol: 'APX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  prl: {
    address: { 56: '0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24', 1120: '' },
    decimals: 18,
    symbol: 'PRL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  fuse: {
    address: { 56: '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3', 1120: '' },
    decimals: 18,
    symbol: 'FUSE',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ertha: {
    address: { 56: '0x62823659d09F9F9D2222058878f89437425eB261', 1120: '' },
    decimals: 18,
    symbol: 'ERTHA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  raca: {
    address: { 56: '0x12BB890508c125661E03b09EC06E404bc9289040', 1120: '' },
    decimals: 18,
    symbol: 'RACA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gear: {
    address: { 56: '0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36', 1120: '' },
    decimals: 18,
    symbol: 'GEAR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ach: {
    address: { 56: '0xBc7d6B50616989655AfD682fb42743507003056D', 1120: '' },
    decimals: 8,
    symbol: 'ACH',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  btt: {
    address: { 56: '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B', 1120: '' },
    decimals: 18,
    symbol: 'BTT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  era: {
    address: { 56: '0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9', 1120: '' },
    decimals: 18,
    symbol: 'ERA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  fight: {
    address: { 56: '0x4f39c3319188A723003670c3F9B9e7EF991E52F3', 1120: '' },
    decimals: 18,
    symbol: 'FIGHT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  loa: {
    address: { 56: '0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50', 1120: '' },
    decimals: 18,
    symbol: 'LOA',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  duet: {
    address: { 56: '0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B', 1120: '' },
    decimals: 18,
    symbol: 'DUET',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gmt: {
    address: { 56: '0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1', 1120: '' },
    decimals: 8,
    symbol: 'GMT',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  bsw: {
    address: { 56: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1', 1120: '' },
    decimals: 18,
    symbol: 'BSW',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tem: {
    address: { 56: '0x19e6BfC1A6e4B042Fb20531244D47E252445df01', 1120: '' },
    decimals: 9,
    symbol: 'TEM',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  pex: {
    address: { 56: '0x6a0b66710567b6beb81A71F7e9466450a91a384b', 1120: '' },
    decimals: 18,
    symbol: 'PEX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  yel: {
    address: { 56: '0xD3b71117E6C1558c1553305b44988cd944e97300', 1120: '' },
    decimals: 18,
    symbol: 'YEL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  tinc: {
    address: { 56: '0x05aD6E30A855BE07AfA57e08a4f30d00810a402e', 1120: '' },
    decimals: 18,
    symbol: 'TINC',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  happy: {
    address: { 56: '0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d', 1120: '' },
    decimals: 18,
    symbol: 'Happy',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  wzrd: {
    address: { 56: '0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4', 1120: '' },
    decimals: 18,
    symbol: 'WZRD',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ceek: {
    address: { 56: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66', 1120: '' },
    decimals: 18,
    symbol: 'CEEK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  abnbc: {
    address: { 56: '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A', 1120: '' },
    decimals: 18,
    symbol: 'aBNBc',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  ankr: {
    address: { 56: '0xf307910A4c7bbc79691fD374889b36d8531B08e3', 1120: '' },
    decimals: 18,
    symbol: 'ANKR',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  gal: {
    address: { 56: '0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5', 1120: '' },
    decimals: 18,
    symbol: 'GAL',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  xcn: {
    address: { 56: '0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b', 1120: '' },
    decimals: 18,
    symbol: 'XCN',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  metis: {
    address: { 56: '0xe552Fb52a4F19e44ef5A967632DBc320B0820639', 1120: '' },
    decimals: 18,
    symbol: 'Metis',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  MIX: {
    address: { 56: '0x398f7827DcCbeFe6990478876bBF3612D93baF05', 1120: '' },
    decimals: 18,
    symbol: 'MIX',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
  peak: {
    address: { 56: '0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78', 1120: '' },
    decimals: 8,
    symbol: 'PEAK',
    projectLink: 'https://matchaswap.zilionixx.com/',
  },
}

export default tokens
